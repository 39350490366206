export const FirebaseConfig = {
	"projectId": "taxiapp-ec",
	"appId": "1:525775386537:web:b808b3e3aa5d3f655ef6b8",
	"databaseURL": "https://taxiapp-ec-default-rtdb.firebaseio.com",
	"storageBucket": "taxiapp-ec.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyCzdHOJtGTycbAYSXDtobH30vKR66h7S6s",
	"authDomain": "taxiapp-ec.firebaseapp.com",
	"messagingSenderId": "525775386537",
	"measurementId": "G-K191JCTMZC"
};